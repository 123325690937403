import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import { ALL_LANGUAGES, ALL_COUNTRIES, LANGUAGE_EN } from '~/utils/constants';
export default (function (_ref, inject) {
  var store = _ref.store;
  var locale = function locale() {
    var _window$navigator$lan, _window, _window$navigator, _window$navigator$lan2;
    var authUserLocale = store.getters['auth/locale'];

    /**
     * if user is not authenticated value will be something like 'fi-undefined',
     * check it and fallback to browser default or en
     */
    var parts = authUserLocale.split('-').filter(function (x) {
      return [].concat(_toConsumableArray(ALL_LANGUAGES), _toConsumableArray(ALL_COUNTRIES)).includes(x);
    });
    if (parts.length === 2) {
      return parts.join('-');
    }
    return (_window$navigator$lan = (_window = window) === null || _window === void 0 ? void 0 : (_window$navigator = _window.navigator) === null || _window$navigator === void 0 ? void 0 : (_window$navigator$lan2 = _window$navigator.languages) === null || _window$navigator$lan2 === void 0 ? void 0 : _window$navigator$lan2[0]) !== null && _window$navigator$lan !== void 0 ? _window$navigator$lan : LANGUAGE_EN;
  };

  /**
   * @param {Date | string} date value to be formatted
   * @param {Intl.DateTimeFormatOptions} opts options for formatting date value
   * @returns formatted date string
   */
  var formatDate = function formatDate(date, opts) {
    return new Intl.DateTimeFormat(locale(), opts).format(date instanceof Date ? date : new Date(date));
  };
  inject('intl', {
    formatDate: formatDate
  });
});