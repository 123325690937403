
import sysend from 'sysend'

export default {
  data () {
    return {
      isModalOpen: false
    }
  },
  computed: {
    activeWorkspaceId () {
      return this.$store.getters['auth/company']?.id
    },
    globalModalOpenState () {
      return this.$store.state.subscription.isOpen
    },
    isPreloadCalled () {
      return this.$store.state.subscription.isPreloadCalled
    },
    requiredTemplateFeatures () {
      return this.$store.state.subscription.requiredTemplateFeatures
    }
  },
  watch: {
    globalModalOpenState (value) {
      this.$nextTick(() => {
        this.isModalOpen = value
      })
    },
    isModalOpen (value) {
      if (value) {
        this.$ph.capture('checkout_started', {
          is_upgrade: false,
        })
        this.$gtm.push({
          event: 'checkout_started',
          is_upgrade: false,
        })
      }

      if (value && this.isPreloadCalled) {
        sysend.broadcast('subscription::open')
      }
    }
  },
  beforeRouteLeave (_, _1, next) {
    if (this.requiredTemplateFeatures) {
      this.$store.dispatch('subscription/setRequiredTemplateFeatures', [])
    }
    if (this.isPreloadCalled) {
      this.$store.dispatch('subscription/setIsPreloadCalled', false)
    }

    next()
  },
  mounted () {
    sysend.on('subscription::modal-closed', this.closeModal)

    sysend.on('subscription::tracker::plan-selected', ({ currency, price, priceId, plan }) => {
      this.$ph.capture('plan_selected', {
        is_upgrade: false,
      })

      this.$gtm.push({
        event: 'add_to_cart',
        ecommerce: {
          currency,
          value: price,
          items: [{
            item_id: priceId,
            item_name: plan,
            price,
            item_category: 'plan',
            quantity: 1,
          }]
        }
      })
    })
    sysend.on('subscription::tracker::activation-completed', ({ planPriceId, annualPrice, currency, couponId, plan }) => {
      this.$store.dispatch('auth/fetchCompany')
      this.$store.dispatch('auth/fetchSubscriptions')
      this.$store.dispatch('auth/fetchFeatures')
      this.$store.dispatch('auth/fetchRecordBalance')

      this.$ph.capture('subscription_created', {
        plan_id: planPriceId
      })
      this.$gtm.push({
        event: 'activateSubscription',
        accountType: 'company',
        planId: planPriceId,
      })

      const transactionId = `${this.activeWorkspaceId}-${Date.now()}`

      this.$gtm.push({
        event: 'newTransaction',
        transactionId,
        transactionTotal: annualPrice,
        transactionProducts: [{
          name: plan,
          category: 'Plan',
          sku: planPriceId,
          price: annualPrice,
          currency,
          quantity: 1,
        }]
      })

      this.$gtm.push({ ecommerce: null })

      this.$gtm.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: transactionId,
          value: annualPrice,
          currency,
          coupon: couponId,
          items: [{
            item_id: planPriceId,
            item_name: plan,
            item_cateogry: 'plan',
            coupon: couponId,
            currency,
            price: annualPrice,
            quantity: 1,
          }]
        }
      })
    })
    sysend.on('subscription::tracker::activation-failed', ({ planPriceId, errorMessage }) => {
      this.$intercom.call('trackEvent', 'activation-failed', {
        planPriceId,
        errorMessage,
      })
    })
    sysend.on('subscription::trigger::show-intercom', () => {
      this.$intercom.call('show')
    })
    sysend.on('subscription::tracker::contact-form-submitted', () => {
      this.$tracker.submitContactForm()
    })
  },
  methods: {
    closeModal () {
      this.$store.dispatch('subscription/updateIsOpen', false)
    }
  }
}
