import get from 'lodash.get'
import {
  removeApiToken,
  removeActiveAccount,
  setApiToken,
  setActiveAccount,
  getActiveAccount,
} from '~/utils/cookie'
import {
  populateRelationships
} from '~/utils/api'
import { ACCOUNT_PERSONAL, ACCOUNT_TYPE_CLIENT, ACCOUNT_TYPE_COMPANY, ROLE_ADMIN, LOGIN_PATH, PREFERENCE_DISABLED_SIGNING_METHODS, TEMPLATE_LANGUAGE_TO_PLAN_FEATURE, SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_NON_RENEWING, SUBSCRIPTION_STATUS_TRIAL, SUBSCRIPTION_STATUS_PAUSED, SUBSCRIPTION_STATUS_CANCELLED, SUBSCRIPTION_STATUS_FUTURE } from '~/utils/constants'
import { clearState } from '~/utils/state'
import { isSubscriptionValid, isSubscriptionNonRenewing } from '~/utils/subscription'
import { popReturn, popTargetAccount } from '~/utils/return'
import { Cache } from '~/utils/cache'

const namespaceCache = new Cache('auth-spaces')

const caches = {}
const useCache = (key = getActiveAccount()) => {
  caches[key] ??= new Cache(key)
  const cache = caches[key]
  return {
    get () {
      return cache.get(...arguments)
    },
    set () {
      namespaceCache.update('active', Array.from(
        new Set(namespaceCache.get('active') ?? []).add(key)
      ))
      cache.set(...arguments)
    },
    clear: () => cache.clear()
  }
}

const clearCaches = () => {
  (namespaceCache.get('active') ?? []).forEach(ns => useCache(ns).clear())
  namespaceCache.clear()
  Object.keys(caches).forEach(k => delete caches[k])
}

export const state = () => ({
  authenticationEmail: '',

  activeAccount: null,
  client: null,
  company: null,
  companies: null,

  algoliaApiKeys: null,
  algoliaApiKeysLoaded: false,

  paymentSources: [],

  features: [],
  featuresLoaded: false,

  recordBalance: {},
  recordBalanceLoaded: false,

  subscriptions: [],
  subscriptionsLoaded: false,

  configuration: null,
  signupFoundExistingAccount: false,
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_AUTHENTICATION_EMAIL (state, email) {
    state.authenticationEmail = email
  },
  SET_ACTIVE_ACCOUNT (state, account) {
    state.activeAccount = account
  },
  SET_CLIENT (state, client) {
    state.client = client
  },
  SET_COMPANY (state, company) {
    state.company = company
  },
  SET_COMPANIES (state, companies) {
    state.companies = companies
  },
  SET_PAYMENT_SOURCES (state, paymentSources) {
    state.paymentSources = paymentSources
  },
  DELETE_PAYMENT_SOURCE (state, id) {
    state.paymentSources = state.paymentSources.filter(s => s.id !== id)
  },
  SET_ALGOLIA_API_KEYS (state, keys) {
    state.algoliaApiKeys = keys
    state.algoliaApiKeysLoaded = true
  },
  SET_FEATURES (state, features) {
    state.features = features
    state.featuresLoaded = true
  },
  SET_RECORD_BALANCE (state, balance) {
    state.recordBalance = balance
    state.recordBalanceLoaded = true
  },
  SET_CONFIGURATION (state, config) {
    state.configuration = config
  },
  SET_SUBSCRIPTIONS (state, subscriptions) {
    state.subscriptions = subscriptions
    state.subscriptionsLoaded = true
  },
  SET_SIGNUP_FOUND_EXISTING_ACCOUNT (state, exists) {
    state.signupFoundExistingAccount = exists
  }
}

export const actions = {
  async setSignupToken ({ dispatch }, token) {
    setApiToken(token)
    setActiveAccount(ACCOUNT_PERSONAL)

    await dispatch('fetchClient')
    const companies = await dispatch('fetchCompanies')

    // If the user already has companies (eq. signing up using google auth
    // with existing account) redirect to account chooser instead
    if (companies.length) {
      this.$router.push('/login/choose-account')
      return
    }

    this.$router.push('/signup/create-user')
  },

  login (_, token) {
    try {
      setApiToken(token)

      const targetAccount = popTargetAccount()
      setActiveAccount(targetAccount || ACCOUNT_PERSONAL)

      const targetUrl = popReturn() || '/'

      // If target account provided redirect
      // directly to the target url
      if (targetAccount) {
        location.assign(targetUrl)
        return
      }

      this.$router.push(`/login/choose-account?return=${encodeURIComponent(targetUrl)}`)

      this.$gtm.push({
        event: 'login'
      })
      this.$ph.capture('login')
    } catch (e) {
      removeApiToken()
      removeActiveAccount()

      // Propagate error
      throw e
    }
  },
  async fetchClient ({ commit, dispatch }) {
    const response = await this.$axios.$get('/me?include=systemPermissions,partner,authProviders').then(populateRelationships)
    await commit('SET_CLIENT', response.data)
    useCache().set('client', response.data)

    // Update ui language based on client's language
    await dispatch('setLanguage', response.data.attributes.language, { root: true })

    if (response.data.attributes.blockedAt) {
      return window.location.assign('/blocked')
    }

    return response.data
  },
  async fetchCompany ({ state, commit }) {
    const account = state.activeAccount
    if (account !== ACCOUNT_PERSONAL) {
      const response = await this.$axios.$get(`/me/companies/${account}?include=role,partner,preferences,brandingLogo`).then(populateRelationships)
      await commit('SET_COMPANY', response.data)
      useCache().set('company', response.data)
    }
  },
  async fetchPaymentSources ({ commit }) {
    const response = await this.$axios.$get('/me/paymentSources')
    await commit('SET_PAYMENT_SOURCES', response.data)
    return response.data
  },
  async removePaymentSource ({ commit }, paymentSourceId) {
    await this.$axios.$delete(`/me/paymentSources/${paymentSourceId}`)
    await commit('DELETE_PAYMENT_SOURCE', paymentSourceId)
  },
  async fetchCompanies ({ state, commit }) {
    const response = await this.$axios.$get('/me/companies?limit=100').then(populateRelationships)

    // Order by display name
    const companies = response.data.sort((a, b) => {
      const aName = a.attributes.displayName
      const bName = b.attributes.displayName
      if (aName < bName) { return -1 }
      if (aName > bName) { return 1 }

      return 0
    })

    await commit('SET_COMPANIES', companies)
    useCache(state.client.id).set('companies', companies)
    return response.data
  },
  async fetchAlgoliaApiKeys ({ commit }) {
    const response = await this.$axios.$get('/me/algolia/securedApiKeys')
    await commit('SET_ALGOLIA_API_KEYS', response.data)
    useCache().set('algolia_api_keys', response.data)
  },
  async fetchFeatures ({ commit }) {
    const response = await this.$axios.$get('/me/features')
    await commit('SET_FEATURES', response.data)
    useCache().set('features', response.data)
  },
  async fetchRecordBalance ({ commit }) {
    const response = await this.$axios.$get('/me/records/balance')
    await commit('SET_RECORD_BALANCE', response.balance)
    useCache().set('record_balance', response.balance)
  },
  async fetchSubscriptions ({ commit, state }) {
    const { data } = await this.$axios.$get(`/me/companies/${state.company.id}/subscription`, {
      params: {
        include: 'addons.addonPrice,contractTerm'
      }
    }).then(populateRelationships)

    await commit('SET_SUBSCRIPTIONS', data)
    useCache().set('subscriptions', data)
  },
  async fetchAccountData ({ state, dispatch, getters, commit }) {
    const clientLoaded = !!state.client
    const activeAccount = state.activeAccount
    const isCompany = activeAccount !== ACCOUNT_PERSONAL
    const stateCompanyId = get(getters.company, 'id')

    async function getData (cacheKey, dispatchAction, commitKey) {
      const cachedData = useCache().get(cacheKey)
      if (cachedData) {
        await commit(commitKey, cachedData)
        // invalidate all cache eventually
        dispatch(dispatchAction)
        return
      }
      await dispatch(dispatchAction)
    }

    if (!clientLoaded || (isCompany && stateCompanyId !== activeAccount)) {
      await Promise.all([
        Promise.resolve(getData('client', 'fetchClient', 'SET_CLIENT')),
        Promise.resolve(getData('company', 'fetchCompany', 'SET_COMPANY'))
      ]).catch((err) => {
        throw err
      })
    }

    // Do not fetch remaining data for clients without customerId
    // These will be fetched again after relevant workspace has been chosen
    if (!isCompany && !state.client.attributes.customerId) {
      return
    }

    await Promise.all(
      [
        Promise.resolve(!state.subscriptionLoaded && isCompany &&
          getData(
            'subscriptions',
            'fetchSubscriptions',
            'SET_SUBSCRIPTIONS'
          )),
        Promise.resolve(!state.featuresLoaded &&
          getData(
            'features',
            'fetchFeatures',
            'SET_FEATURES'
          )),
        Promise.resolve(!state.recordBalanceLoaded &&
          getData(
            'record_balance',
            'fetchRecordBalance',
            'SET_RECORD_BALANCE'
          )),
        Promise.resolve(!state.configuration &&
          getData(
            'config',
            'fetchConfiguration',
            'SET_CONFIGURATION'
          )),
        Promise.resolve(!state.algoliaApiKeysLoaded &&
          getData(
            'algolia_api_keys',
            'fetchAlgoliaApiKeys',
            'SET_ALGOLIA_API_KEYS'
          ))
      ]
    ).catch((err) => {
      throw err
    })
  },
  async fetchConfiguration ({ commit }) {
    const response = await this.$axios.$get('/v2/configuration')
    await commit('SET_CONFIGURATION', response.data)
    useCache().set('config', response.data)
  },
  async logout (_, returnUrl = null) {
    // Invalidate token
    try {
      await this.$axios.$post('/logout')
    } catch (e) {
      this.$log.error(e)
    }

    clearCaches()
    removeApiToken()
    removeActiveAccount()

    this.$intercom.call('shutdown')
    this.$ph.reset()

    window.location.assign(returnUrl || LOGIN_PATH)
  },
}

export const getters = {
  /**
   * Returns active company and null if personal account
   */
  company (state) {
    const account = state.activeAccount
    if (account === ACCOUNT_PERSONAL) {
      return null
    }

    return state.company
  },

  companies: state => (getCachedValue = false) => {
    const cached = getCachedValue && useCache(state.client.id).get('companies')
    return state.companies ?? (cached?.length ? cached : [])
  },

  mainSubscription (state) {
    // Backend returns all subscriptions, even cancelled and trial subscriptions.
    // Main subscription is the first active subscription and if no active, first trial
    // subscription. Workspace can have one active and one trial subscription in some cases

    // Try to find active
    let subscription = state.subscriptions.find(s => s.attributes.status === SUBSCRIPTION_STATUS_ACTIVE || s.attributes.status === SUBSCRIPTION_STATUS_NON_RENEWING)
    if (subscription) {
      return subscription
    }

    // Try to find trial subscription
    subscription = state.subscriptions.find(s => s.attributes.status === SUBSCRIPTION_STATUS_TRIAL)
    if (subscription) {
      return subscription
    }

    // Return future if found
    subscription = state.subscriptions.find(s => s.attributes.status === SUBSCRIPTION_STATUS_FUTURE)
    if (subscription) {
      return subscription
    }

    // Return paused if found
    subscription = state.subscriptions.find(s => s.attributes.status === SUBSCRIPTION_STATUS_PAUSED)
    if (subscription) {
      return subscription
    }

    // Return any non-cancelled subscription
    return state.subscriptions.find(s => s.attributes.status !== SUBSCRIPTION_STATUS_CANCELLED)
  },

  /**
   * If the subscription for active account is "valid".
   */
  hasValidSubscription (state, getters) {
    return state.subscriptions.some(s => isSubscriptionValid(s))
  },

  /**
   * If workspace has any paid subscription (active sub, which is not in trial)
   */
  hasPaidSubscription (state, getters) {
    return state.subscriptions.some(s => s.attributes.status === SUBSCRIPTION_STATUS_NON_RENEWING || s.attributes.status === SUBSCRIPTION_STATUS_ACTIVE)
  },

  /**
   * If workspace has any future subscriptions
   */
  hasFutureSubscription (state, getters) {
    return state.subscriptions.some(s => s.attributes.status === SUBSCRIPTION_STATUS_FUTURE)
  },

  /**
   * If workspace has any trial subscription
   */
  hasTrialSubscription (state, getters) {
    return state.subscriptions.some(s => s.attributes.status === SUBSCRIPTION_STATUS_TRIAL)
  },

  /**
   * If the subscription for active workspace is in trial
   */
  isTrialSubscription (_, getters) {
    return getters.mainSubscription?.attributes?.status === SUBSCRIPTION_STATUS_TRIAL
  },

  /**
   * If the subscription for active account has been scheduled for cancellation.
   */
  isSubscriptionNonRenewing (_, getters) {
    return isSubscriptionNonRenewing(getters.mainSubscription) || !!getters.mainSubscription?.attributes.cancelledAt
  },

  hasBuilderSubscription (_, getters) {
    if (!getters.hasValidSubscription) {
      return false
    }

    return Object.values(TEMPLATE_LANGUAGE_TO_PLAN_FEATURE).some(feature => getters.isFeatureEnabled(feature))
  },

  isAdmin (state, getters) {
    const company = getters.company
    if (company) {
      return get(company, 'relationships.role.data.attributes.type') === ROLE_ADMIN
    }

    return false
  },

  isFeatureEnabled (state, getter) {
    return (feature) => {
      return get(state, `features.${feature}.enabled`) || false
    }
  },

  hasBuilderAccess (state, getters) {
    if (getters.hasValidSubscription && !getters.isFeatureEnabled('builder')) {
      return false
    }
    return true
  },

  getBalance (state) {
    return (record, defaultValue = null) => {
      return get(state, `recordBalance.${record}`, defaultValue)
    }
  },

  account (state, getters) {
    let account = null
    const company = getters.company
    if (company) {
      account = {
        displayName: company.attributes.displayName,
        type: ACCOUNT_TYPE_COMPANY,
        isCompany: true,
        id: company.id,
        country: company.attributes.country,
        partner: {
          type: company?.relationships?.partner?.data?.attributes?.type,
          pricingUrl: company?.relationships?.partner?.data?.attributes?.pricingUrl,
          logo: company?.relationships?.partner?.data?.links?.logoUrl,
          homeBanner: {
            url: company?.relationships?.partner?.data?.links?.homeBannerUrl,
            style: company?.relationships?.partner?.data?.attributes?.homeBannerStyle
          }
        }
      }
    } else if (state.client) {
      account = {
        displayName: state.client.attributes.name,
        type: ACCOUNT_TYPE_CLIENT,
        isCompany: false,
        id: state.client.id,
        country: state.client.attributes.country,
        partner: {
          type: state.client?.relationships?.partner?.data?.attributes?.type,
          pricingUrl: state.client?.relationships?.partner?.data?.attributes?.pricingUrl,
          logo: state.client?.relationships?.partner?.data?.links?.logoUrl,
          homeBanner: {
            url: state.client?.relationships?.partner?.data?.links?.homeBannerUrl,
            style: state.client?.relationships?.partner?.data?.attributes?.homeBannerStyle
          }
        }
      }
    }

    return account
  },

  country (state, getters) {
    return getters.account?.country
  },

  locale (state, getters, rootState) {
    return `${rootState.language}-${getters.country}`
  },

  currencyFormat (state) {
    if (!state.configuration?.currency_code) {
      return null
    }

    return {
      key: 'currency',
      currency: state.configuration?.currency_code,
    }
  },

  longCurrencyFormat (state) {
    if (!state.configuration?.currency_code) {
      return null
    }

    return {
      key: 'currency',
      currency: state.configuration?.currency_code,
      minimumFractionDigits: 2,
    }
  },

  config (state) {
    return state.configuration
  },

  supportEmail (state) {
    return state.configuration?.support_email ?? 'support@docue.com'
  },

  /**
   * Allowed signing methods for the authenticated workspace
   */
  signMethods (state, getters) {
    const methods = getters.config?.signing_methods ?? []
    const disabledMethods = getters.company?.relationships?.preferences?.data?.find(p => p.attributes.key === PREFERENCE_DISABLED_SIGNING_METHODS)?.attributes.value || []

    return methods.filter(method => !disabledMethods.includes(method.type))
  },

  newArchiveEnabled (state, getters) {
    return !!getters.company?.attributes?.root_folder_id
  }
}
